import { IVCalendarData, IVCalendarEventData, VCalendarEventStatus } from './IVCalendarData';
import { saveAs } from 'file-saver';

export class VCalendar implements IVCalendarData {
    public id: string;
    public productId: string;
    public events: VCalendarEvent[] = [];

    public get version(): string {
        return '2.0';
    }

    constructor(data?: IVCalendarData) {
        if (data) {
            this.id = data.id;
            this.productId = data.productId;
            for (let i = 0; i < data.events.length; i++) {
                const event = new VCalendarEvent(data.events[i]);
                this.events.push(event);
            }
        }
    }

    public toVCalString(): string {
        let events = '';
        for (let i = 0; i < this.events.length; i++) {
            events += this.events[i].toVCalString();
        }
        return (
            `BEGIN:VCALENDAR\n` +
            `PRODID:-//${this.productId}//${this.id}//EN\n` +
            `VERSION:${this.version}\n` +
            events +
            `\nEND:VCALENDAR`
        );
    }

    public toVCalBlob() {
        let blob: Blob;
        console.log(navigator.userAgent, Blob);
        //if (navigator.userAgent.indexOf('MSIE 10') === -1 && navigator.userAgent.indexOf('Edge') === -1) { // chrome or firefox
            blob = new Blob([this.toVCalString()], {
                type: 'text/x-vCalendar;charset=utf-8'
            });
      //  } else {// ie
       //     const bb = new MSBlobBuilder();
      //      bb.append(this.toVCalString());
      //      blob = bb.getBlob('text/x-vCalendar;charset=utf-8');
     //   }

        return blob;
    }

    public download(filename: string) {
        saveAs(this.toVCalBlob(), filename + '.ics');
      /*  const a = <HTMLAnchorElement>document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(this.toVCalBlob());
        a.href = url;
        a.download = filename + '.ics';
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);*/
    }

}

export class VCalendarEvent implements IVCalendarEventData {
    public id: string | number;
    public namespace: string;
    public lang: string;
    public status: VCalendarEventStatus;
    public created: Date;
    public start: Date;
    public end: Date;
    public summary: string;
    public description: string;

    constructor(data?: IVCalendarEventData) {
        if (data) {
            this.id = data.id;
            this.namespace = data.namespace;
            this.lang = data.lang;
            this.status = data.status;
            this.created = data.created;
            this.start = data.start;
            this.end = data.end;
            this.summary = data.summary;
            this.description = data.description;
        }
    }

    public toVCalString(): string {
        const start_year = ('0000' + (this.start.getFullYear().toString())).slice(-4);
        const start_month = ('00' + ((this.start.getMonth() + 1).toString())).slice(-2);
        const start_day = ('00' + ((this.start.getDate()).toString())).slice(-2);
        const start_hours = ('00' + (this.start.getHours().toString())).slice(-2);
        const start_minutes = ('00' + (this.start.getMinutes().toString())).slice(-2);
        const start_seconds = ('00' + (this.start.getSeconds().toString())).slice(-2);

        const end_year = ('0000' + (this.end.getFullYear().toString())).slice(-4);
        const end_month = ('00' + ((this.end.getMonth() + 1).toString())).slice(-2);
        const end_day = ('00' + ((this.end.getDate()).toString())).slice(-2);
        const end_hours = ('00' + (this.end.getHours().toString())).slice(-2);
        const end_minutes = ('00' + (this.end.getMinutes().toString())).slice(-2);
        const end_seconds = ('00' + (this.end.getSeconds().toString())).slice(-2);

        const now_year = ('0000' + (this.created.getFullYear().toString())).slice(-4);
        const now_month = ('00' + ((this.created.getMonth() + 1).toString())).slice(-2);
        const now_day = ('00' + ((this.created.getDate()).toString())).slice(-2);
        const now_hours = ('00' + (this.created.getHours().toString())).slice(-2);
        const now_minutes = ('00' + (this.created.getMinutes().toString())).slice(-2);
        const now_seconds = ('00' + (this.created.getSeconds().toString())).slice(-2);

        const start_time = 'T' + start_hours + start_minutes + start_seconds;
        const end_time = 'T' + end_hours + end_minutes + end_seconds;
        const now_time = 'T' + now_hours + now_minutes + now_seconds;

        const start = start_year + start_month + start_day + start_time;
        const end = end_year + end_month + end_day + end_time;
        const created = now_year + now_month + now_day + now_time;

        return (
            `BEGIN:VEVENT\n` +
            `UID:${this.id}@${this.namespace}\n` +
            `CLASS:PRIVATE\n` +
            `STATUS:${this.status}\n` +
            `DTSTAMP;VALUE=DATE-TIME:${created}\n` +
            `DTSTART;VALUE=DATE-TIME:${start}\n` +
            `DTEND;VALUE=DATE-TIME:${end}\n` +
            `SUMMARY;LANGUAGE=${this.lang}:${this.summary}\n` +
            `DESCRIPTION:${this.description}\n` +
            `TRANSP:TRANSPARENT\n` +
            `END:VEVENT\n`
        );
    }
}
