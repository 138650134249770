import { IMenuDescriptor, MenuAction, CanActivateMethod } from './IMenuDescriptor';

export declare type DefaultMenuItemType = 'item' | 'index';



export interface IDefaultMenuItem {
    type: DefaultMenuItemType;
    label: string;
    icon?: string;
    children?: IDefaultMenuItem[] | Promise<IDefaultMenuItem[]>;
    action?: MenuAction;
    canActivate?: boolean | Promise<boolean> | CanActivateMethod;
}

export class DefaultMenuDescriptor implements IMenuDescriptor<IDefaultMenuItem> {

    getLabel(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): string {
        return item.label;
    }
    getIcon(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): string {
        return item.icon;
    }
    async getChildren(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): Promise<IDefaultMenuItem[]> {
        return await item.children;
    }
    async isBranch(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): Promise<boolean> {
        const children = await this.getChildren(item, root);
        if (!children || !children.length) {
            return true;
        }
        if (children.length === 1 && this.isIndex(children[0], root)) {
            return true;
        }
        return false;
    }

    getAction(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): MenuAction {
        return item.action;
    }

    canActivate(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): boolean | Promise<boolean> | CanActivateMethod {
        return item.canActivate;
    }

    async getIndex(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): Promise<IDefaultMenuItem> {
        const children = await this.getChildren(item, root);
        return this.findIndex(children, root);
    }

    isIndex(item: IDefaultMenuItem, root?: IDefaultMenuItem | IDefaultMenuItem[]): boolean {
        return item.type === 'index';
    }

    private findIndex(children: IDefaultMenuItem[], root?: IDefaultMenuItem | IDefaultMenuItem[]): IDefaultMenuItem {
        if (children && children.length) {
            return children.find(child => this.isIndex(child, root));
        }
        return undefined;
    }


}
