import { trigger, animate, style, group, transition, AnimationTriggerMetadata, state } from '@angular/animations';


export const searchStateTransition: AnimationTriggerMetadata = trigger('stateTransition', [
    state('open', style({
        display: 'block',
        width: '*',
        'min-width': '*',
        opacity: 1
    })),
    state('closed', style({
        display: 'none',
        width: '0px',
        'min-width': '0px',
        opacity: 0
    })),
    transition('open => closed', [
        style({ width: '*', opacity: 1, 'min-width': '*', display: 'block' }),
        animate('0.15s ease-out', style({ 'min-width': '0px', width: '0px', opacity: 0 })),
    ]),
    transition('closed => open', [
        style({ width: '0px', opacity: 0, 'min-width': '0px', display: 'block' }),
        animate('0.15s  ease-in', style({ width: '*', opacity: 1, 'min-width': '*' })),
    ])
]);
