
import { NavigationExtras } from '@angular/router';

export interface IURLAction {
    url: string;
    target?: string;
}

export function isURLAction(obj: any): obj is IURLAction {
    return typeof obj.url === 'string';
}

export interface IRouteAction {
    commands: any[];
    options?: NavigationExtras;
}

export function isRouteAction(obj: any): obj is IRouteAction {
    return Array.isArray(obj.commands);
}


export declare type MenuAction = Function | string | IURLAction | any[] | IRouteAction;
export declare type CanActivateMethod = () => boolean | Promise<boolean>;

export interface IMenuDescriptor<TItem, TRoot extends TItem | TItem[]= TItem | TItem[]> {
    getLabel(item: TItem, root?: TRoot): string;
    getIcon(item: TItem, root?: TRoot): string;
    getChildren(item: TItem, root?: TRoot): Promise<TItem[]>;
    isBranch(item: TItem, root?: TRoot): Promise<boolean>;
    getAction(item: TItem, root?: TRoot): MenuAction;
    canActivate(item: TItem, root?: TRoot): boolean | Promise<boolean> | CanActivateMethod;
    getIndex(item: TItem, root?: TRoot): TItem | Promise<TItem>;
    isIndex(item: TItem, root?: TRoot): boolean;
}
