import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyModule } from './sticky/sticky.module';
@NgModule({
  imports: [
    CommonModule,
    StickyModule
  ],
  declarations: [],
  exports: [StickyModule]
})
export class LayoutModule { }
