import { NgModule } from '@angular/core';
import { PageModule } from './containers/index';

@NgModule({
  imports: [
    PageModule
  ],
  exports: [
    PageModule
  ],
  declarations: []
})
export class AngularWebComponentsModule { }
