import { NgModule } from '@angular/core';
import { BreadCrumbNavModule } from './bread-crumb-nav/index';

@NgModule({
  imports: [
    BreadCrumbNavModule,
  ],
  exports: [
    BreadCrumbNavModule
  ]
})
export class NavigationModule { }
