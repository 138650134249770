
import { Inject, Injectable } from '@angular/core';
import { IInMemoryUserModel } from './IInMemoryUserModel';
import { BaseAuthenticationProvider } from '../../base/BaseAuthenticationProvider';
import { ILoginAuthenticationProvider, ILoginConfig } from '../../IAuthenicationProvider';
import { ISimpleLoginCredentials, IAuthenticatedUser } from '../../model/index';
import { IN_MEMORY_USERS } from './token';
import { CancelablePromise } from '@nfc-authority/ts-core';


@Injectable({
    providedIn: 'root'
})
export class InMemoryAuthenticationProvider extends BaseAuthenticationProvider<IAuthenticatedUser> implements
    ILoginAuthenticationProvider<IAuthenticatedUser, ISimpleLoginCredentials> {
    public static readonly PROVIDER_ID = 'in-memory';

    public readonly providerId = InMemoryAuthenticationProvider.PROVIDER_ID;
    public readonly providerName = 'In Memory';
    public readonly type: 'login' = 'login';

    constructor(
        @Inject(IN_MEMORY_USERS) private users: IInMemoryUserModel[]
    ) {
        super();
        console.log('@@@@@@@@@@@@@@@@@@@@@@');
    }

    public get config(): ILoginConfig {
        return {
            supportsPasswordRecovery: false
        };
    }

    logout(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    login(cred?: ISimpleLoginCredentials): Promise<IAuthenticatedUser> {
        return new CancelablePromise<IAuthenticatedUser>((res, rej) => {
            const tid = setTimeout(() => {
                const user = this.users.find(_ => _.username === cred.username);
                if (user && user.password === cred.password) {
                    this.setAuthenticatedUser(user);
                    res({
                        id: user.username,
                        username: user.username,
                    });
                } else {
                    rej(new Error('Invalid Username or Password'));
                }

            }, 1000);
            return () => {
                clearTimeout(tid);
            };
        }, this.authCancelToken);
    }

}
