import { Component, OnInit, Input, HostBinding, ChangeDetectorRef } from '@angular/core';
import { IMenuDescriptor } from './menu-descriptor/IMenuDescriptor';
import { DefaultMenuDescriptor } from './menu-descriptor/DefaultMenuDescriptor';
import { EnumValue } from '../tscore/type-conversion/string/decorators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { SubnavMode } from './menu-item/SubnavMode';
import { ConstantPool } from '@angular/compiler';
export enum MenuLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

@Component({
  selector: 'ngwc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  private _menuItems: any[] = [];
  private _data: any | any[];
  private _filteredMenuItems: Promise<any[]>;
  public overlayRef: OverlayRef;

  @Input()
  public itemDescriptor: IMenuDescriptor<any> = new DefaultMenuDescriptor();

  @Input()
  @HostBinding('attr.layout')
  public layout: MenuLayout = MenuLayout.VERTICAL;

  @Input()
  public showIcons: boolean = false;

  @Input()
  public subnavMode: SubnavMode | SubnavMode[] = SubnavMode.POPUP;

  public get childLayout(): MenuLayout {
    return MenuLayout.VERTICAL;
  }

  @Input()
  public get data(): any | any[] {
    return this._data;
  }
  public set data(value: any | any[]) {
    if (!Array.isArray(value) && !!value) {
      value = [value];
    }
    this._data = value;
    this._menuItems = value;
    this.update();
  }

  public update() {
    this._filteredMenuItems = new Promise(async (res, rej) => {
      const filteredItems = [];
      for (let i = 0; i < this._menuItems.length; i++) {
        const item = this._menuItems[i];
        let canActivate = this.itemDescriptor.canActivate(item);
        if (canActivate === undefined) {
          filteredItems.push(item);
        } else {
          if (typeof (canActivate) === 'function') {
            canActivate = canActivate();
          }
          const result = await canActivate;
          if (result === true) {
            filteredItems.push(item);
          }
        }
      }
      res(filteredItems);
      this.overlayRef.updatePosition();
    });
    this.changeDetectorRef.markForCheck();
  }

  public get menuItems(): Promise<any[]> {
    return this._filteredMenuItems;
  }

  constructor(
    private overlay: Overlay,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.overlayRef = overlay.create({
      hasBackdrop: false,
      height: '100%',
      width: '100%',
    });

  }

  ngOnInit() {
  }

}
